import log from "loglevel";
import toast from "src/libs/toast";
import { useHistory } from "react-router-dom";
import {
  Activity,
  ActivityStatus,
  ActivityType,
  User,
  useMutationUpdateActivity,
} from "src/graphql";
import { isFlowBasedActivityType } from "src/pages/templates-activity/TemplateForm";
import { useAuthContext } from "./useAuthContext";

export const useStartActivity = () => {
  const { currentUser, selectedOrganizationId } = useAuthContext();
  const history = useHistory();
  const [updateActivity] = useMutationUpdateActivity();

  return async (activity: Activity) => {
    const lastStartedAt = new Date().toISOString();
    await updateActivity({
      variables: {
        input: {
          activityId: activity._id,
          updateActivity: {
            userId: currentUser._id,
            status: ActivityStatus.InProgress,
            lastStartedAt,
          },
          organizationId: selectedOrganizationId,
        },
      },
    }).catch((error) => {
      log.error(error);
      toast.error("Could not start activity successfully");
    });

    const flowBasedTasksNotCompleted = activity.tasks
      .filter(
        (task) =>
          isFlowBasedActivityType(task.type) ||
          task.type === ActivityType.Event,
      )
      .some(({ status }) => status !== ActivityStatus.Complete);

    if (flowBasedTasksNotCompleted)
      return void history.push(`/activity/${activity._id}`);

    toast.error("Could not start activity");
  };
};

export const canStartActivity = (
  currentUser: User,
  activity: Activity,
): boolean => {
  const flowBasedTasksNotCompleted = activity.tasks
    .filter((task) => isFlowBasedActivityType(task.type))
    .some(({ status }) => status !== ActivityStatus.Complete);

  const isEvent = activity.tasks?.[0]?.event;

  const isStartable =
    activity.status !== ActivityStatus.Complete &&
    (flowBasedTasksNotCompleted ||
      activity.tasks.length === 0 ||
      isEvent !== undefined);

  const notAllowedToBeStarted =
    activity.userId !== currentUser._id || activity.hasMissingMembers;

  return isStartable && !notAllowedToBeStarted;
};
