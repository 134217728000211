import { Button, Stack, Text } from "@mantine/core";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import log from "loglevel";
import { useMemo, useRef, useState } from "react";
import { Save } from "react-feather";

import {
  EditableItem,
  FormErrorNotice,
  FormikInput,
  FormikMultiSelect,
  FormikSelect,
  makeEditableItemsArray,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalSubHeader,
  StyledErrorMessage,
  StyledLabel,
} from "src/components";
import { ActionItemList } from "src/components/form/goal-template-form/ActionItemList";
import { FormikTextEditor } from "src/components/input/FormikTextEditor/FormikTextEditor";
import {
  AnswerType,
  Question,
  useMutationCreateQuestion,
  useMutationUpdateQuestion,
  usePaginatedQueryTags,
} from "src/graphql";
import { useAuthContext } from "src/hooks";
import { useDataPointIdsWithAnswerTypes } from "src/hooks/useDataPointIdsWithAnswerTypes";
import { CustomErrorMessage } from "src/pages/recommendations/EditRecommendationView";
import { SelectOption } from "src/types";
import { arrayToKeyedObj, omitFields } from "src/utils";
import { BASE_QUESTION_ID } from "./QuestionListPane";
import { QuestionFormSchema } from "./QuestionSchema";
import { WarnModalContent } from "src/components/warn-locking-references-modal/WarningModalContent";

type QuestionEditorModalProps = {
  selectedQuestion: Question;
  onCreateQuestion?: () => void;
  onUpdateQuestion?: (question: Question) => void;
  onRequestClose: () => void;
};

export const QuestionEditorModal = ({
  selectedQuestion,
  onCreateQuestion,
  onUpdateQuestion,
  onRequestClose,
}: QuestionEditorModalProps) => {
  const formikRef = useRef<FormikProps<QuestionFormValues> | null>(null);

  const { selectedOrganizationId } = useAuthContext();
  const { answerTypesByDataId, defaultOptionsByDataId } =
    useDataPointIdsWithAnswerTypes(selectedOrganizationId);
  const [errorMessage, setErrorMessage] = useState("");
  const [submissionPending, setSubmissionPending] = useState(false);
  const [usingPredefinedDataId, setUsingPredefinedDataId] = useState(false);
  const [mutationCreateQuestion] = useMutationCreateQuestion(
    selectedOrganizationId,
    selectedQuestion.questionType,
  );
  const [mutationUpdateQuestion] = useMutationUpdateQuestion();

  const organizationTags = usePaginatedQueryTags(selectedOrganizationId, 100);
  const organizationTagOptions = useMemo(
    () =>
      organizationTags.data?.data.map((tag) => ({
        label: tag.label,
        value: tag.label,
      })) ?? [],
    [organizationTags],
  );

  const wrappedValues = useMemo(
    () => (selectedQuestion ? wrapFormValues(selectedQuestion) : null),
    [selectedQuestion],
  );

  const [showWarningView, setShowWarningView] = useState(false);
  const [pendingFormValues, setPendingFormValues] =
    useState<QuestionFormValues>();

  const locked = !!selectedQuestion.lockingReferences?.length;

  const handleSubmit = async (
    formValues: QuestionFormValues,
    formikHelpers: FormikHelpers<QuestionFormValues>,
  ) => {
    setSubmissionPending(true);

    const input = { ...formValues };
    delete input.dataIdTemp;
    delete input.answerTypesByDataId;
    delete input.dataPointTemplateId;
    delete input.dataPointTemplate;
    delete input._count;
    delete input.tags;
    delete input.createdTags;
    delete input.accessType;

    if (selectedQuestion._id === BASE_QUESTION_ID) {
      await handleCreateQuestion(input);
    } else {
      setPendingFormValues(input);
      if (locked) {
        setShowWarningView(true);
      } else {
        await handleUpdateQuestion(input);
      }
    }

    formikHelpers.resetForm({ values: formValues });
    setSubmissionPending(false);
  };

  const handleCancelUpdate = () => {
    setShowWarningView(false);
    onRequestClose?.();
  };

  const handleCreateQuestion = async (formValues: QuestionFormValues) => {
    try {
      const { lockingReferences, ...unwrapped } = unwrapFormValues(formValues);
      const res = await mutationCreateQuestion({
        variables: {
          input: {
            ...unwrapped,
            questionType: selectedQuestion.questionType,
          },
        },
      });

      if (res.errors?.length || res.data?.createQuestion?.success === false) {
        setErrorMessage("Oops! Something went wrong. Please try again.");
      } else {
        onCreateQuestion?.();
        onRequestClose();
      }
    } catch {
      setErrorMessage("Oops! Something went wrong. Please try again.");
    }
  };

  const handleUpdateQuestion = async (
    values: QuestionFormValues | undefined,
  ) => {
    if (!values) return;
    try {
      const { lockingReferences, ...unwrapped } = unwrapFormValues(values);
      const res = await mutationUpdateQuestion({
        variables: {
          input: {
            _id: selectedQuestion._id,
            ...unwrapped,
          },
        },
      });

      if (
        res.errors?.length ||
        res.data?.updateQuestion?.success === false ||
        !res.data?.updateQuestion.data
      ) {
        setErrorMessage("Oops! Something went wrong. Please try again.");
      } else {
        onUpdateQuestion?.(res.data?.updateQuestion.data);
        onRequestClose();
      }
    } catch (e) {
      log.error(e);
      setErrorMessage("Oops! Something went wrong. Please try again.");
    }
    setPendingFormValues(undefined);
    setShowWarningView(false);
  };

  const onAnswerTypeChange = (e: SelectOption<AnswerType> | null) => {
    if (formikRef.current && e) {
      const wasReadOnlyQuestion =
        formikRef.current.values.answerType.value === AnswerType.ReadOnlyText;

      const updatedValues = { ...formikRef.current.values };

      if (e?.value === AnswerType.ReadOnlyText) {
        updatedValues.dataId = DEFAULT_READONLY_DATA_ID;
      } else if (wasReadOnlyQuestion) {
        updatedValues.dataId = "";
      }

      updatedValues.answerType = e;
      formikRef.current.setValues(updatedValues);
    }
  };

  const dataIdOptions: SelectOption<string>[] = useMemo(() => {
    return Object.keys(answerTypesByDataId).map((dataId) => ({
      value: dataId,
      label: `${dataId} - ${answerTypesByDataId[dataId]}`,
    }));
  }, [answerTypesByDataId]);

  const onDataIdChange = (nextValue: SelectOption<string> | null) => {
    if (formikRef.current && nextValue) {
      const updatedValues = {
        ...formikRef.current.values,
        dataIdTemp: nextValue,
        dataId: nextValue.value,
      };

      if (dataIdOptions.includes(nextValue)) {
        setUsingPredefinedDataId(true);
        const answerType = answerTypesByDataId[nextValue?.value ?? ""];
        const answerTypeOption = answerTypeOptions.find(
          (op) => op.value === answerType,
        );

        if (answerTypeOption) updatedValues.answerType = answerTypeOption;

        if (
          ([AnswerType.Multi, AnswerType.MultiChoice] as string[]).includes(
            updatedValues.answerType.value,
          )
        ) {
          updatedValues.answerOptions = makeEditableItemsArray(
            defaultOptionsByDataId[nextValue.value],
          );
        } else {
          updatedValues.answerOptions = [];
        }
      } else {
        setUsingPredefinedDataId(false);
      }

      formikRef.current.setValues(updatedValues);
    }
  };

  const renderQuestionForm = () => (
    <>
      {wrappedValues && (
        <Formik
          innerRef={formikRef}
          initialValues={{
            ...wrappedValues,
            answerTypesByDataId,
            dataIdTemp:
              wrappedValues && wrappedValues.dataId
                ? {
                    value: wrappedValues.dataId,
                    label: `${wrappedValues.dataId} - ${
                      answerTypesByDataId[wrappedValues.dataId]
                    }`,
                  }
                : undefined,
            tagNames:
              selectedQuestion?.tags?.map((taggedEntity) => ({
                label: taggedEntity?.tag?.label as string,
                value: taggedEntity?.tag?.label as string,
              })) ?? [],
            createdTags: [],
          }}
          validationSchema={QuestionFormSchema}
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          {({ values, dirty, handleSubmit, setFieldValue, errors }) => {
            if (dirty && !!errorMessage) {
              setErrorMessage("");
            }
            const isReadOnlyTextQuestion = values.answerType
              ? values.answerType.value === AnswerType.ReadOnlyText
              : false;

            return (
              <Form onSubmit={handleSubmit}>
                {locked && (
                  <ModalSubHeader>
                    <Text size="sm">
                      Some attributes are locked due to use in a Flow Template.
                    </Text>
                  </ModalSubHeader>
                )}

                <ModalBody spacing="0.75em">
                  <Stack spacing={0}>
                    <FormikSelect
                      name="dataIdTemp"
                      label={
                        isReadOnlyTextQuestion
                          ? "Default Data ID (non-editable)"
                          : "Data ID"
                      }
                      creatable
                      onCreate={(dataId) => ({ label: dataId, value: dataId })}
                      onChangeOverride={onDataIdChange}
                      placeholder="Select or Write Data ID"
                      options={dataIdOptions}
                      disabled={locked || isReadOnlyTextQuestion}
                    />
                    <CustomErrorMessage
                      errors={errors}
                      fieldName="dataId"
                      submitCount={1}
                    />
                  </Stack>

                  <FormikInput
                    type="text"
                    name="questionTitle"
                    label={`${selectedQuestion.questionType} Title`}
                    placeholder="Enter Title..."
                  />

                  <FormikTextEditor
                    name="questionText"
                    label={`${selectedQuestion.questionType} Text`}
                    placeholder="Enter Text..."
                    onChangeOverride={(value: string) =>
                      setFieldValue("questionText", value)
                    }
                  />

                  <FormikMultiSelect
                    name="tagNames"
                    placeholder="Select or create Tags"
                    label="Tags"
                    options={[
                      ...organizationTagOptions,
                      ...(values?.createdTags ?? []),
                    ]}
                    onChangeOverride={(nextTags) =>
                      setFieldValue("tagNames", nextTags)
                    }
                    creatable
                    onCreate={(newTag) => {
                      setFieldValue("createdTags", [
                        ...(values?.createdTags ?? []),
                        newTag,
                      ]);
                      return { label: newTag, value: newTag };
                    }}
                    value={values.tagNames}
                  />

                  <FormikSelect
                    name="answerType"
                    label="Answer Type"
                    options={answerTypeOptions}
                    placeholder="Select answer type..."
                    disabled={locked || usingPredefinedDataId}
                    onChangeOverride={(nextItem) => {
                      onAnswerTypeChange(nextItem);
                    }}
                  />

                  {(
                    [AnswerType.Multi, AnswerType.MultiChoice] as string[]
                  ).includes(values.answerType.value) && (
                    <Stack mt="lg" spacing={0}>
                      <StyledLabel>Answer Options</StyledLabel>
                      <ActionItemList
                        itemsById={arrayToKeyedObj(
                          values.answerOptions ?? {},
                          "id",
                        )}
                        onChange={(nextItems) => {
                          // filter duplicates pre-save
                          const answerSet = new Set();
                          const nextValues = Object.values(nextItems).filter(
                            (item) => {
                              // consider only character content, no tabs/spcs/newlines
                              const cleaned = item.content.replace(
                                /[\s\t\n\r]/g,
                                "",
                              );
                              if (answerSet.has(cleaned)) return false;
                              return answerSet.add(cleaned);
                            },
                          );
                          setFieldValue("answerOptions", nextValues);
                        }}
                      ></ActionItemList>
                      <StyledErrorMessage name="answerOptions" />
                    </Stack>
                  )}

                  {errorMessage && (
                    <FormErrorNotice errorMessage={errorMessage} />
                  )}
                </ModalBody>

                <ModalFooter>
                  <Button
                    loading={submissionPending}
                    onClick={onRequestClose}
                    color="red"
                    variant="outline"
                  >
                    Cancel
                  </Button>

                  <Button
                    disabled={!dirty}
                    type="submit"
                    leftIcon={<Save size="18" />}
                  >
                    {selectedQuestion._id === BASE_QUESTION_ID
                      ? "Create"
                      : "Update"}
                  </Button>
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );

  return (
    <Modal opened={!!selectedQuestion} onClose={onRequestClose}>
      <ModalHeader withSubHeader={locked}>
        {selectedQuestion._id === BASE_QUESTION_ID
          ? `Create ${selectedQuestion.questionType}`
          : `Edit ${selectedQuestion.questionType}`}
      </ModalHeader>

      {showWarningView ? (
        <WarnModalContent
          lockedObject="Question"
          activeReferences={selectedQuestion.lockingReferences}
          onConfirm={() => handleUpdateQuestion(pendingFormValues)}
          onCancel={handleCancelUpdate}
          isLoading={submissionPending}
          errorMessage={errorMessage}
        />
      ) : (
        renderQuestionForm()
      )}
    </Modal>
  );
};

// fields to be omitted from wrapped value
const formOmittedFields = [
  "_id",
  "createdAt",
  "updatedAt",
  "questionType",
] as const;
type FormOmittedFields = (typeof formOmittedFields)[number];

// fields from Question to wrap with SelectOption<T>, etc.
type FormRemappedFields = "answerType" | "answerOptions";

// type for wrapped form types representing Question
export type QuestionFormValues = Omit<
  Question,
  FormOmittedFields | FormRemappedFields
> & {
  answerType: SelectOption<AnswerType>;
  answerOptions: EditableItem[];
  answerTypesByDataId?: Record<string, AnswerType>;
  dataIdTemp?: SelectOption<string>;
  tagNames: SelectOption<string>[];
  createdTags?: SelectOption<string>[];
};

// DROQ1 -> "Default Read Only Question 1"
const DEFAULT_READONLY_DATA_ID = "DROQ1";

const answerTypeOptions: SelectOption<AnswerType>[] = [
  {
    label: "True/False",
    value: AnswerType.Boolean,
  },
  {
    label: "Choose One",
    value: AnswerType.Multi,
  },
  {
    label: "Choose Many",
    value: AnswerType.MultiChoice,
  },
  {
    label: "Number",
    value: AnswerType.Number,
  },
  {
    label: "Text",
    value: AnswerType.Text,
  },
  {
    label: "Date",
    value: AnswerType.Date,
  },
  {
    label: "Read Only Text",
    value: AnswerType.ReadOnlyText,
  },
];

// Wraps form values in w/e format required by inputs
const wrapFormValues = (question: Question): QuestionFormValues => ({
  ...omitFields(question, formOmittedFields),
  answerType: answerTypeOptions.find(
    (opt) => opt.value === question.answerType,
  ) ?? { label: "True/False", value: AnswerType.Boolean },
  answerOptions: makeEditableItemsArray(question.answerOptions ?? []),
  tagNames:
    question.tags?.map((taggedEntity) => ({
      label: taggedEntity.tag?.label as string,
      value: taggedEntity.tag?.label as string,
    })) ?? [],
  createdTags: [],
});

// Unwraps form values from input-accepted types back to base data, minus omitted fields
const unwrapFormValues = (values: QuestionFormValues) => ({
  ...values,
  answerType: values.answerType.value,
  answerOptions: values.answerOptions.map((opt) => opt.content),
  tagNames: values.tagNames.map((tag) => tag.value),
});
