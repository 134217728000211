import { Reference, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GET_BUILDER_GRAPH } from "../Builder/queries";
import {
  DefaultResponse,
  GetFlowTemplateResponse,
  GetActivityTemplatesWithAccessResponse,
  MutationCopyFlowTemplateArgs,
  MutationCreateFlowTemplateArgs,
  MutationExportFlowTemplateDataArgs,
  MutationUpdateFlowTemplateArgs,
  QueryGoalTemplatesArgs,
  QueryFlowTemplateArgs,
  QueryFlowTemplatesArgs,
  QueryActivityTemplatesArgs,
  MutationRetireFlowTemplateFamilyArgs,
  GetFlowTemplateFamilyResponse,
  QueryFlowTemplateFamilyArgs,
  GetFlowTemplateFamiliesResponse,
  QueryFlowTemplateFamiliesArgs,
  CarePathwayTemplatesResponse,
  ConsentTemplatesResponse,
  GetFlowTemplatesWithAccessResponse,
  GetFlowTemplateFamiliesWithAccessResponse,
  GetGoalTemplatesWithAccessRes,
  GetExternalResourcesResponse,
} from "../schemaTypes";
import {
  COPY_FLOW_TEMPLATE,
  CREATE_FLOW_TEMPLATE,
  EXPORT_FLOW_DATA,
  RETIRE_FLOW_TEMPLATE_FAMILY,
  UPDATE_FLOW_TEMPLATE,
} from "./mutations";
import {
  GET_FLOW_TEMPLATE,
  GET_FLOW_TEMPLATES,
  GET_FLOW_TEMPLATE_FAMILY,
  GET_FLOW_TEMPLATE_FAMILIES,
  GET_FLOW_BUILDER_DATA,
} from "./queries";

export const useQueryFlowTemplates = (organizationId: string) =>
  useQuery<
    { flowTemplates: GetFlowTemplatesWithAccessResponse },
    QueryFlowTemplatesArgs
  >(GET_FLOW_TEMPLATES, {
    variables: {
      organizationId,
    },
  });

export type GetFlowBuilderDataResponse = {
  goalTemplates: GetGoalTemplatesWithAccessRes;
  flowTemplateFamilies: GetFlowTemplateFamiliesWithAccessResponse;
  activityTemplates: GetActivityTemplatesWithAccessResponse;
  externalResources: GetExternalResourcesResponse;
  carePathwayTemplatesByOrganizationId: CarePathwayTemplatesResponse;
  consentTemplatesByOrganizationId: ConsentTemplatesResponse;
};

type QueryFlowBuilderDataArgs = QueryGoalTemplatesArgs &
  QueryFlowTemplateFamiliesArgs &
  QueryActivityTemplatesArgs;

export const useQueryFlowBuilderData = (organizationId: string) =>
  useQuery<GetFlowBuilderDataResponse, QueryFlowBuilderDataArgs>(
    GET_FLOW_BUILDER_DATA,
    { variables: { organizationId } },
  );

export const useQueryFlowTemplate = (
  organizationId: string,
  flowTemplateId: string,
) =>
  useQuery<{ flowTemplate: GetFlowTemplateResponse }, QueryFlowTemplateArgs>(
    GET_FLOW_TEMPLATE,
    {
      variables: {
        input: {
          flowTemplateId,
          organizationId,
        },
      },
    },
  );

export const useLazyQueryFlowTemplate = (
  organizationId: string,
  flowTemplateId: string,
) =>
  useLazyQuery<
    { flowTemplate: GetFlowTemplateResponse },
    QueryFlowTemplateArgs
  >(GET_FLOW_TEMPLATE, {
    variables: {
      input: {
        flowTemplateId,
        organizationId,
      },
    },
  });

export const useQueryFlowTemplateFamily = (
  organizationId: string,
  familyId: string,
) =>
  useQuery<
    { flowTemplateFamily: GetFlowTemplateFamilyResponse },
    QueryFlowTemplateFamilyArgs
  >(GET_FLOW_TEMPLATE_FAMILY, {
    variables: { input: { organizationId, familyId } },
  });

export const useQueryFlowTemplateFamilies = (organizationId: string) =>
  useQuery<
    { flowTemplateFamilies: GetFlowTemplateFamiliesResponse },
    QueryFlowTemplateFamiliesArgs
  >(GET_FLOW_TEMPLATE_FAMILIES, { variables: { organizationId } });

export const useMutationExportFlowTemplateData = () =>
  useMutation<
    { exportFlowTemplateData: DefaultResponse },
    MutationExportFlowTemplateDataArgs
  >(EXPORT_FLOW_DATA);

export const useMutationCreateFlowTemplate = (organizationId: string) =>
  useMutation<
    { createFlowTemplate: GetFlowTemplateResponse },
    MutationCreateFlowTemplateArgs
  >(CREATE_FLOW_TEMPLATE, {
    refetchQueries: [GET_BUILDER_GRAPH],

    update: (cache, result) => {
      const newVersion = result.data?.createFlowTemplate.data;
      if (!newVersion) return;

      const newVersionCacheId = cache.identify(newVersion);
      if (!newVersionCacheId) return;

      cache.modify({
        fields: {
          flowTemplateFamilies: (cached: {
            data: {
              currentVersion: Reference;
              priorVersions: Reference[];
            }[];
          }) => {
            return {
              ...cached,
              data: [
                ...cached.data,
                {
                  currentVersion: { __ref: newVersionCacheId },
                  priorVersions: [],
                },
              ],
            };
          },

          flowTemplates: (cached: { data: Reference[] }) => {
            return {
              ...cached,
              data: [...cached.data, { __ref: newVersionCacheId }],
            };
          },
        },
      });
    },
  });

export const useMutationUpdateFlowTemplate = () =>
  useMutation<
    { updateFlowTemplate: GetFlowTemplateResponse },
    MutationUpdateFlowTemplateArgs
  >(UPDATE_FLOW_TEMPLATE, {
    refetchQueries: [GET_BUILDER_GRAPH],

    update: (cache, result) => {
      const newVersion = result.data?.updateFlowTemplate.data;
      if (!newVersion) return;

      const newVersionCacheId = cache.identify(newVersion);
      if (!newVersionCacheId) return;

      cache.modify({
        fields: {
          flowTemplates: (
            cached: {
              data: Reference[];
            },
            { readField },
          ) => ({
            ...cached,
            data: cached.data.map((cachedRef) =>
              readField("familyId", cachedRef) === newVersion.familyId
                ? { __ref: newVersionCacheId }
                : cachedRef,
            ),
          }),
          flowTemplateFamilies: (
            cached: {
              data: {
                currentVersion: Reference;
                priorVersions: Reference[];
              }[];
            },
            { readField },
          ) => {
            // find the refs in the cached templateFamilies array
            const matchedFamilyRefs = (cached.data ?? []).find((cachedFam) => {
              return (
                readField("familyId", cachedFam.currentVersion) ===
                newVersion.familyId
              );
            });
            if (!matchedFamilyRefs) return;

            // update the templateFamily to point the currentVersion to the new version,
            // while bumping the old currentVersion to the priorVersions array
            return {
              ...cached,
              data: cached.data.map((cachedFam) => {
                if (
                  cachedFam.currentVersion.__ref !==
                  matchedFamilyRefs.currentVersion.__ref
                )
                  return cachedFam;

                const prevVersionRef = cachedFam.currentVersion;
                return {
                  ...cachedFam,
                  currentVersion: { __ref: newVersionCacheId },
                  priorVersions: [prevVersionRef, ...cachedFam.priorVersions],
                };
              }),
            };
          },
        },
      });
    },
  });

export const useMutationRetireFlowTemplateFamily = (organizationId: string) =>
  useMutation<
    { retireFlowTemplateFamily: GetFlowTemplateResponse },
    MutationRetireFlowTemplateFamilyArgs
  >(RETIRE_FLOW_TEMPLATE_FAMILY, {
    refetchQueries: [
      { query: GET_FLOW_BUILDER_DATA, variables: { organizationId } },
    ],
  });

export const useMutationCopyFlowTemplate = (organizationId: string) =>
  useMutation<
    { copyFlowTemplate: GetFlowTemplateResponse },
    MutationCopyFlowTemplateArgs
  >(COPY_FLOW_TEMPLATE, {
    refetchQueries: [
      { query: GET_FLOW_BUILDER_DATA, variables: { organizationId } },
    ],
  });
