import {
  Flex,
  Loader,
  MultiSelect,
  SelectItem,
  Text,
  Tooltip,
} from "@mantine/core";
import { useField } from "formik";
import { forwardRef, useMemo, useState } from "react";
import {
  extractWrapperProps,
  FormikInputBaseProps,
  FormikInputWrapper,
} from "./FormikInputWrapper";
import { Info } from "react-feather";

type FormikMultiSelectProps<Option extends SelectItem> = FormikInputBaseProps<
  Option[] | null
> & {
  options: Option[];
  isLoading?: boolean;
  withinPortal?: boolean;
  itemComponent?: React.FC<unknown>;
  searchable?: boolean;
  onSearchChange?: (value: string) => void;
  // Overrides the default Formik onChange handler external control
  onChangeOverride?: (options: Option[] | null) => void;
  onOptionSelect?: (options: SelectItem[] | null) => void;
  createOnly?: boolean;
  disabledItemToolTipMessage?: string;
  questionTooltip?: string;
  maw?: number;
} & (
    | { creatable: true; onCreate: (query: string) => Option }
    | { creatable?: false; onCreate?: undefined }
  );

export const FormikMultiSelect = <Option extends SelectItem>(
  props: FormikMultiSelectProps<Option>,
) => {
  const {
    maw,
    options,
    placeholder = "Select one or more",
    disabled,
    onChangeOverride,
    itemComponent,
    withinPortal,
    creatable,
    searchable,
    onSearchChange,
    isLoading,
    createOnly,
    disabledItemToolTipMessage,
    onOptionSelect,
  } = props;

  const [field, , helpers] = useField({ ...props, type: "select" });
  const [createdOptions, setCreatedOptions] = useState<Option[]>([]);

  const resolvedOptions = useMemo(
    () => [...createdOptions, ...options],
    [createdOptions, options],
  );

  const handleChange = (values: string[]) => {
    const selectedOptions = values
      .map(
        (value) =>
          // The fallback here is because handleCreate and handleChange
          // are executed in same render, due to which the createdOptions
          // would not include the created option
          resolvedOptions.find((option) => option.value === value) ||
          (props.creatable ? props.onCreate(value) : null),
      )
      .filter((option): option is Option => option !== undefined);

    if (onChangeOverride) onChangeOverride(selectedOptions);
    else helpers.setValue(selectedOptions);

    if (onOptionSelect) onOptionSelect(selectedOptions);
  };

  const handleCreate = !props.creatable
    ? undefined
    : (query: string) => {
        const option = props.onCreate(query);
        setCreatedOptions((existent) => [option, ...existent]);
        return option;
      };

  const RightSection = () => {
    if (createOnly) return <></>;
    return isLoading ? <Loader size="xs" /> : null;
  };

  return (
    <FormikInputWrapper {...extractWrapperProps(props)}>
      <MultiSelect
        {...field}
        maw={maw}
        placeholder={placeholder}
        nothingFound={createOnly ? undefined : "No options"}
        disabled={disabled || isLoading}
        data={options}
        value={field.value?.map((option) => option.value)}
        onChange={handleChange}
        creatable={creatable}
        onCreate={handleCreate}
        getCreateLabel={(query) => `+ Create ${query}`}
        searchable={searchable}
        onSearchChange={onSearchChange}
        withinPortal={withinPortal}
        itemComponent={
          itemComponent ??
          (disabledItemToolTipMessage
            ? DisabledSelectOption(disabledItemToolTipMessage)
            : undefined)
        }
        rightSection={<RightSection />}
      />
    </FormikInputWrapper>
  );
};

const DisabledSelectOption = (reason: string) =>
  forwardRef<HTMLDivElement, SelectItem>(
    ({ label, questions, ...others }: SelectItem, ref) => (
      <Flex justify="space-between" align="center" ref={ref} {...others}>
        <Text size="sm">{label}</Text>
        {others.disabled && (
          <Tooltip
            withArrow
            withinPortal
            position="right"
            label={<Text size="sm">{reason}</Text>}
          >
            <Info
              size={14}
              color={others["data-selected"] ? "white" : "var(--GREEN)"}
              cursor="pointer"
            />
          </Tooltip>
        )}
      </Flex>
    ),
  );
