import { Select } from "@mantine/core";
import { Editor } from "@tiptap/react";
import { useGetOrganizationDataPoints } from "./hook";
import { useAuthContext } from "src/hooks";
import { useState } from "react";

type PlaceholderSelectProps = {
  editor: null | Editor;
};

const PlaceholdersSelectMenu = ({ editor }: PlaceholderSelectProps) => {
  const { selectedOrganizationId } = useAuthContext();
  const [opened, setOpened] = useState(false);
  const [value, setValue] = useState<string | null>(null);
  const { data: datapoints } = useGetOrganizationDataPoints(
    selectedOrganizationId,
  );

  const onSelectChange = (dataId?: string) => {
    if (!dataId || !editor) return;
    const datapoint = datapoints.find((d) => d.value === dataId);
    if (!datapoint) return;

    const label = datapoint?.required
      ? datapoint.label
      : `${datapoint.label} | default`;

    editor.chain().focus().insertContent(`{{ ${label} }} `).run();

    if (datapoint?.required) {
      return;
    }

    // 3 because of space, }} and next position
    const to = editor.state.selection.from - 4;
    const from = to - 7; // "default".length is 7

    // select word "default"
    editor.commands.setTextSelection({ from, to });

    editor.view.focus();
  };

  if (!datapoints) return null;
  return (
    <Select
      size="xs"
      value={value}
      data={datapoints}
      maxDropdownHeight={130}
      dropdownPosition="bottom"
      placeholder={opened ? "Select a placeholder" : "Insert Placeholder"}
      onChange={(val) => {
        setValue(val);
        onSelectChange(val || undefined);
      }}
      onDropdownOpen={() => setOpened(true)}
      onDropdownClose={() => {
        setOpened(false);
        setValue(null);
      }}
    />
  );
};

export default PlaceholdersSelectMenu;
