import { gql } from "@apollo/client";
import { certificationFields, certificationTemplateFields } from "./types";

export const CREATE_OR_UPDATE_CERTIFICATION_TEMPLATE = gql`
  mutation CreateOrUpdateCertificationTemplate($input: CertificationTemplateInput!) {
    createOrUpdateCertificationTemplate(input: $input) {
      success
      message
      data {
        ${certificationTemplateFields}
      }
    }
  }
`;

export const CREATE_OR_UPDATE_CERTIFICATION = gql`
  mutation CreateOrUpdateCertification($input: CertificationInput!) {
    createOrUpdateCertification(input: $input) {
      success
      message
      data {
        ${certificationFields}
      }
    }
  }
`;

export const DELETE_CERTIFICATION_TEMPLATE = gql`
  mutation DeleteCertificationTemplate($certificationTemplateId: ID!, $organizationId: ID!) {
    deleteCertificationTemplate(certificationTemplateId: $certificationTemplateId, organizationId: $organizationId) {
      success
      message
      data {
        ${certificationTemplateFields}
      }
    }
  }
`;

export const REVOKE_CERTIFICATION = gql`
  mutation RevokeCertification($certificationId: ID!, $organizationId: ID!, $userId: ID!) {
    revokeCertification(certificationId: $certificationId, organizationId: $organizationId, userId:$userId) {
      success
      message
      data {
        ${certificationFields}
      }
    }
  }
`;
