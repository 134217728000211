import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Delete } from "react-feather";
import {
  ActivityStatus,
  AnswerType,
  ExecuteActionInput,
  ScheduleActivityData,
  UndoLastFlowProgressInput,
  useMutationUndoLastFlowProgress,
  useMutationUpdateActivity,
  useMutationUpdateActivityTaskStatus,
  useMutationUpdateFlowProgress,
  useQueryGetNextFlowQuestion,
  useQueryOnboardingFlow,
} from "src/graphql";
import { Button, Stack } from "@mantine/core";
import { ActionDataCollectionModal } from "src/components/flow-display/ActionDataCollectionModal";
import { QuestionWidget } from "src/components/flow-display/QuestionWidget";
import {
  flattenActions,
  getActionsNeedingInput,
  IndividualActionData,
} from "src/components/flow-graph/util";
import log from "loglevel";
import toast from "src/libs/toast";
import { useAuthContext } from "src/hooks";

const CompletionRow = styled.div`
  padding: 10px 30px;
  font-size: 1.25rem;
  text-align: center;
`;

export type FlowProps = {
  onboardingToken: string;
  organizationId: string;
  memberId: string;
  activityId: string;
  activityTaskId: number | null;
  onComplete: () => void;
};

const Flow = ({
  onboardingToken,
  organizationId,
  memberId,
  activityId,
  activityTaskId,
  onComplete,
}: FlowProps) => {
  const { currentUser } = useAuthContext();
  const [currentAnswer, setCurrentAnswer] = useState("");
  const [actionDataModalIsOpen, setActionDataModalIsOpen] = useState(false);
  const [submissionsThisSession, setSubmissionsThisSession] = useState(0);
  const [actionsToInputDataFor, setActionsToInputDataFor] = useState<
    IndividualActionData[]
  >([]);

  const { data: flowResponse, loading: flowLoading } = useQueryOnboardingFlow(
    memberId,
    organizationId,
    activityId,
    onboardingToken,
  );

  const flow = flowResponse?.onboardingFlow.data;

  const [
    mutationUpdateActivityTaskStatus,
    { loading: updateActivityTaskStatusLoading },
  ] = useMutationUpdateActivityTaskStatus(onboardingToken);

  const [mutationUpdateActivity, { loading: updateActivityLoading }] =
    useMutationUpdateActivity(onboardingToken);

  const [mutationSubmitAnswer, { loading: answerSubmissionLoading }] =
    useMutationUpdateFlowProgress(organizationId, memberId, onboardingToken);
  const [mutationUndoLastAnswer, { loading: undoSubmissionLoading }] =
    useMutationUndoLastFlowProgress(onboardingToken);

  const {
    data: questionResponse,
    refetch: refetchQuestion,
    loading: questionLoading,
  } = useQueryGetNextFlowQuestion(
    organizationId,
    flow?._id ?? "",
    onboardingToken,
  );

  const currentQuestion = questionResponse?.getNextFlowQuestion?.data?.question;

  const actionsByResponse =
    questionResponse?.getNextFlowQuestion?.data?.actions?.reduce(
      (byResponse, action) => ({
        ...byResponse,
        [action.response]: flattenActions(action),
      }),
      {} as Record<string, IndividualActionData[]>,
    ) ?? {};

  const collectActionData = () => {
    if (!currentQuestion) return; // type narrowing
    try {
      // grab global question actions
      const defaultActions = actionsByResponse["DEFAULT"] ?? [];

      // grab actions attached to answers
      const answerValues =
        currentQuestion?.answerType === AnswerType.MultiChoice
          ? (JSON.parse(currentAnswer) as string[])
          : [currentAnswer];
      const answerActions = answerValues.flatMap(
        (value) => actionsByResponse[value] ?? [],
      );

      // we only need to collect user input for scheduleActivity type actions, so filter
      // everything else out
      const allActions = [...defaultActions, ...answerActions];
      const actionsNeedingInput = getActionsNeedingInput(allActions);

      setActionsToInputDataFor(actionsNeedingInput);

      // if we need any data, prompt the user, otherwise submit
      if (actionsNeedingInput.length) {
        setActionDataModalIsOpen(true);
      } else {
        const scheduleActivityImmediately = allActions.filter((action) => {
          return (
            action.__typename === "ScheduleActivityData" &&
            action.scheduleImmediately
          );
        });
        if (scheduleActivityImmediately.length) {
          const currentTime = new Date().toISOString();
          const activityData = scheduleActivityImmediately.map((action) => ({
            activityTemplateId: (action as ScheduleActivityData)
              .activityTemplateId,
            activityTime: currentTime,
            userId: currentUser._id,
          }));

          handleSubmit(activityData);
        } else {
          handleSubmit([]);
        }
      }
    } catch (e) {
      log.error(e);
      toast.error(
        "Action Data Collection Failed. Please Reload and Try Again.",
      );
    }
  };

  const resetQuestionState = () => {
    setActionsToInputDataFor([]);
    setCurrentAnswer("");
    refetchQuestion();
  };

  const handleSubmit = async (extraData: ExecuteActionInput[]) => {
    try {
      setActionDataModalIsOpen(false);
      setActionsToInputDataFor([]);

      await mutationSubmitAnswer({
        variables: {
          input: {
            flowId: flow?._id ?? "",
            answer: currentAnswer,
            currentStep:
              questionResponse?.getNextFlowQuestion?.data?.currentStep ?? "0",
            executeActionInput: extraData,
            activityId,
            organizationId,
          },
        },
      });

      setSubmissionsThisSession((subs) => subs + 1);
      resetQuestionState();
    } catch (e) {
      toast.error("Answer Submission Failed. Please Reload and Try Again.");
    }
  };

  const handleUndoLastAnswer = async () => {
    if (!flow?._id) throw new Error("Missing Flow in undo handler");
    try {
      const input: UndoLastFlowProgressInput = {
        organizationId: organizationId,
        flowId: flow._id,
        activityId,
      };
      await mutationUndoLastAnswer({ variables: { input } });

      setSubmissionsThisSession((subs) => subs - 1);
      resetQuestionState();
    } catch (e) {
      toast.error("Answer undo failed. Please reload and try again.");
    }
  };

  const handleCancelActionCollection = () => {
    setActionsToInputDataFor([]);
    setActionDataModalIsOpen(false);
  };

  const finishActivity = useCallback(async () => {
    try {
      const { data } = await mutationUpdateActivityTaskStatus({
        variables: {
          input: {
            organizationId,
            activityId,
            taskId: activityTaskId as number,
            status: ActivityStatus.Complete,
          },
        },
      });

      const updateActivityResult = await mutationUpdateActivity({
        variables: {
          input: {
            activityId: activityId,
            updateActivity: { status: ActivityStatus.Complete },
            organizationId: organizationId,
          },
        },
      });

      if (updateActivityResult.data?.updateActivity.success === false) {
        throw new Error(updateActivityResult.data.updateActivity.message);
      }

      if (data?.updateActivityTaskStatus.success === false)
        throw new Error(data.updateActivityTaskStatus.message);
    } catch (error) {
      toast.error(
        error instanceof Error
          ? error.message
          : "Unknown error, could not submit activity successfully",
      );
    }
  }, [
    activityId,
    activityTaskId,
    mutationUpdateActivity,
    mutationUpdateActivityTaskStatus,
    organizationId,
  ]);

  useEffect(() => {
    if (
      submissionsThisSession > 0 &&
      !questionLoading &&
      currentQuestion === undefined
    ) {
      // Mark activity as complete
      finishActivity();
      onComplete();
    }
  }, [
    submissionsThisSession,
    currentQuestion,
    questionLoading,
    finishActivity,
    onComplete,
  ]);

  return (
    <>
      <Stack align="flex-start" spacing={0}>
        <Button
          mx={20}
          color="red"
          onClick={handleUndoLastAnswer}
          disabled={submissionsThisSession === 0}
          loading={undoSubmissionLoading}
          leftIcon={<Delete size={18} />}
        >
          Undo Last Answer
        </Button>

        <QuestionWidget
          loading={
            flowLoading ||
            questionLoading ||
            updateActivityTaskStatusLoading ||
            updateActivityLoading
          }
          question={currentQuestion}
          answer={currentAnswer}
          setAnswer={setCurrentAnswer}
          onSubmit={collectActionData}
          submitLoading={answerSubmissionLoading}
        />

        {currentQuestion === undefined && (
          <CompletionRow>
            Thank you for completing the survey.
            <br />
            You may now close this window.
          </CompletionRow>
        )}
      </Stack>

      <ActionDataCollectionModal
        onRequestClose={handleCancelActionCollection}
        modalIsOpen={actionDataModalIsOpen}
        onSubmit={handleSubmit}
        actionsToCollectDataFor={actionsToInputDataFor}
        onboardingToken={onboardingToken}
        // in this context (onboarding flows), users are assigned by lottery on the backend
        assignableUserOptions={[]}
        assignableUsersLoading={false}
        organizationId={organizationId}
      />
    </>
  );
};

export default Flow;
