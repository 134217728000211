import {
  ActionIcon,
  Badge,
  Group,
  Stack,
  Text,
  Tooltip,
  Accordion,
  useMantineTheme,
} from "@mantine/core";

import { Eye, EyeOff, Edit } from "react-feather";
import { Note, Permission } from "src/graphql";

import { getFormattedDate } from "src/utils";
import { IconActivity } from "@tabler/icons-react";
import { NoteDisplay } from "./NoteDisplay";
import { useAuthContext } from "src/hooks";

interface NotesListProps {
  filteredGroupedNotes: {
    latestNote: Note;
    previousVersions: Note[];
    versions: Note[];
    isRedacted: boolean;
  }[];
  redactNote: (note: Note) => Promise<void>;
  handleEditClick: (note: Note) => void;
  readOnly?: boolean;
}

export const NotesList = ({
  filteredGroupedNotes,
  redactNote,
  handleEditClick,
  readOnly = false,
}: NotesListProps) => {
  const theme = useMantineTheme();
  const { hasAnyPermission } = useAuthContext();

  const getNoteVersion = (versions: Note[], latestNote: Note) => {
    return `Version ${
      versions.findIndex((v: Note) => v._id === latestNote._id) + 1
    }`;
  };

  if (filteredGroupedNotes.length === 0) {
    return (
      <Text align="center" color="gray">
        No notes found
      </Text>
    );
  }
  return (
    <Stack spacing="xs">
      {filteredGroupedNotes.map(
        ({ latestNote, previousVersions, versions, isRedacted }) => {
          const header = `${getFormattedDate(latestNote.createdAt, true)} - ${
            latestNote.authorName
          }`;

          const noteTitle = latestNote.title
            ? latestNote.title
            : (latestNote.noteTemplateTitle ?? "");

          return (
            <Accordion key={latestNote._id} variant="separated">
              <Accordion.Item value={latestNote._id}>
                <Accordion.Control>
                  <Group position="apart">
                    <Stack spacing={0}>
                      <Text
                        weight={700}
                        td={isRedacted ? "line-through" : "none"}
                      >
                        {noteTitle}
                      </Text>
                      <Text size="xs" color="dimmed">
                        {header}
                      </Text>
                      <Text size="xs" color="gray">
                        {getNoteVersion(versions, latestNote)}
                      </Text>
                      {/* Display a preview of the note content */}
                      <Text
                        size="sm"
                        color="dark"
                        italic
                        weight={500}
                        lineClamp={1}
                        td={isRedacted ? "line-through" : "none"}
                      >
                        {/* {contentPreview} */}
                        {latestNote.content}
                      </Text>
                    </Stack>
                    <Group spacing="xs">
                      {latestNote.activityTitle && (
                        <Badge
                          size="sm"
                          color="green"
                          leftSection={<IconActivity size={14} />}
                        >
                          {latestNote.activityTitle}
                        </Badge>
                      )}

                      {latestNote.domains &&
                        latestNote.domains.map((domain) => (
                          <Badge
                            color={theme.colors.blue[7]}
                            size="sm"
                            variant="filled"
                          >
                            {domain.title}
                          </Badge>
                        ))}
                      {!isRedacted && !readOnly && (
                        <Tooltip
                          label={
                            !hasAnyPermission(Permission.MemberWriteAccess)
                              ? "You do not have member write access"
                              : "Edit"
                          }
                        >
                          <ActionIcon
                            size="sm"
                            onClick={() => handleEditClick(latestNote)}
                            disabled={
                              !hasAnyPermission(Permission.MemberWriteAccess)
                            }
                          >
                            <Edit
                              size={14}
                              color={
                                hasAnyPermission(Permission.MemberWriteAccess)
                                  ? theme.colors.blue[7]
                                  : "gray"
                              }
                            />
                          </ActionIcon>
                        </Tooltip>
                      )}

                      {!readOnly && (
                        <Tooltip
                          label={
                            !hasAnyPermission(Permission.MemberWriteAccess)
                              ? "You do not have member write access"
                              : latestNote.redacted
                                ? "Unredact Note"
                                : "Redact Note"
                          } // Dynamic tooltip based on redaction state
                        >
                          <ActionIcon
                            size="sm"
                            color={
                              !hasAnyPermission(Permission.MemberWriteAccess)
                                ? "gray"
                                : latestNote.redacted
                                  ? "red"
                                  : "green"
                            }
                            onClick={() => redactNote(latestNote)}
                            disabled={
                              !hasAnyPermission(Permission.MemberWriteAccess)
                            }
                          >
                            {latestNote.redacted ? (
                              <EyeOff size={14} />
                            ) : (
                              <Eye size={14} />
                            )}
                          </ActionIcon>
                        </Tooltip>
                      )}
                    </Group>
                  </Group>
                </Accordion.Control>
                <Accordion.Panel>
                  <NoteDisplay
                    content={latestNote.content}
                    noteTemplateId={latestNote?.noteTemplateId}
                  />
                  {previousVersions.length > 0 && (
                    <Accordion variant="contained">
                      {previousVersions.map((version) => (
                        <Accordion.Item
                          value={version._id}
                          key={version._id}
                          mt="md"
                        >
                          <Accordion.Control>
                            <Group position="apart">
                              {`${getNoteVersion(
                                versions,
                                version,
                              )} from ${getFormattedDate(
                                version.createdAt,
                                true,
                              )}`}

                              {version.activityTitle && (
                                <Badge
                                  size="sm"
                                  color="green"
                                  leftSection={<IconActivity size={14} />}
                                >
                                  {version.activityTitle}
                                </Badge>
                              )}
                            </Group>
                          </Accordion.Control>
                          <Accordion.Panel>
                            <NoteDisplay
                              content={version.content}
                              noteTemplateId={version?.noteTemplateId}
                            />
                          </Accordion.Panel>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  )}
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          );
        },
      )}
    </Stack>
  );
};
