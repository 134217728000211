import { createContext, useContext } from "react";
import { FlowType, JourneyStagePositions, Question } from "src/graphql";
import { FlowBuilderData } from "../hooks";
import { GrabbableJourneyStage } from "../journey-stage-toolbar";
import { ActionInfo } from "../util";

// context and hook for accessing callbacks from node components
type FlowBuilderContextType = {
  builderData: FlowBuilderData;
  readOnly: boolean;
  nodesSupportActions: boolean;
  grabbedStage: GrabbableJourneyStage | null;
  stagePositions: JourneyStagePositions;
  flowType: FlowType;
  onDeleteAction: (nodeId: string, actionInfo: ActionInfo) => void;
  onDeleteStepNode: (nodeId: string) => void;
  onToggleMultiOutput: (nodeId: string) => void;
  onToggleActionModal: (nodeId: string) => void;
  handleUpdateQuestionNodes: (updatedQuestion: Question) => void;
};

export const FlowBuilderContext = createContext<FlowBuilderContextType>(
  {} as FlowBuilderContextType,
);

export const useFlowBuilderContext = () => useContext(FlowBuilderContext);
