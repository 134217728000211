import { Button, Card, Stack, Text } from "@mantine/core";
import {
  Dispatch,
  ReactChild,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  AnswerType,
  AssessmentQuestion,
  useQueryGetAssessment,
  useMutationSubmitAssessment,
  useQueryGetFlowByActivity,
} from "src/graphql";
import toast from "src/libs/toast";
import {
  BooleanInput,
  DateInput,
  MultiChoiceInput,
  MultiInput,
  NumberInput,
  TextInput,
} from "../flow-display/QuestionDisplayCards";
import { useAuthContext } from "src/hooks";

type AssessmentProps = {
  readonly memberId: string;
  readonly activityId: string;
  readonly flowTemplateFamilyId: string;
  readonly onboardingToken?: string;
  readonly organizationId?: string;
  readonly completeAssessmentMessage?: ReactChild;
  readonly gotoNextTask?: () => void;
  readonly onComplete?: () => void;
  readonly pingSignal?: () => void;
};

export const Assessment = ({
  memberId,
  activityId,
  flowTemplateFamilyId,
  onboardingToken,
  organizationId,
  completeAssessmentMessage = "Assessment Complete!",
  gotoNextTask,
  onComplete,
  pingSignal,
}: AssessmentProps) => {
  const { selectedOrganizationId } = useAuthContext();
  const [answers, setAnswers] = useState<Record<string, string>>({});
  const [errors, setErrors] = useState<Record<string, string>>({});

  const flowQuery = useQueryGetFlowByActivity({
    memberId,
    activityId,
    flowTemplateFamilyId,
    onboardingToken,
    organizationId: organizationId ?? selectedOrganizationId,
  });

  const flow = flowQuery.data?.getFlowByActivity.data;
  const assessmentQuery = useQueryGetAssessment(
    organizationId ?? selectedOrganizationId,
    flow?._id,
    onboardingToken,
  );
  const assessment = assessmentQuery.data?.getAssessment.data;

  const [submitAssessment] = useMutationSubmitAssessment(onboardingToken);

  const questions = useMemo(() => {
    if (!assessment || !Array.isArray(assessment.questions)) return [];

    const rootId = assessment.firstStepId;
    const rootNode = assessment.questions.find((q) => q.stepId === rootId);

    if (!rootNode) return [];

    const questions = Object.fromEntries(
      assessment.questions.map((question) => [question.stepId, question]),
    );

    return getAssessmentQuestions(questions, answers, rootNode);
  }, [assessment, answers]);

  useEffect(() => {
    if (!flow) return;

    const flowAnswers = flow?.answers ?? [];
    const answers = Object.fromEntries(
      flowAnswers.map(({ stepId, answer }) => [stepId, answer]),
    );

    setAnswers((prev) => ({ ...prev, ...answers }));
  }, [flow]);

  if (!assessment || !flow) return null;

  const handleSubmit = async () => {
    try {
      const mappedAnswers = questions.map((question) => ({
        question,
        answer: answers[question.stepId],
      }));

      const errors = mappedAnswers.reduce(
        (errors, { question, answer }) => {
          if (question.answerType !== AnswerType.ReadOnlyText && !answer)
            return {
              ...errors,
              [question.stepId]: "This field is required",
            };

          return errors;
        },
        {} as Record<string, string>,
      );

      if (Object.keys(errors).length) {
        setErrors(errors);
        return;
      }

      await submitAssessment({
        variables: {
          input: {
            organizationId: organizationId ?? selectedOrganizationId,
            activityId: activityId,
            flowId: flow._id,
            answers: mappedAnswers.map(({ question, answer = "" }) => ({
              stepId: question.stepId,
              answer,
            })),
          },
        },
      });

      pingSignal?.();
      gotoNextTask?.();
      onComplete?.();
    } catch (error) {
      const defaultErrorMessage =
        "An error occurred while submitting the assessment";

      toast.error(
        error instanceof Error
          ? (error.message ?? defaultErrorMessage)
          : defaultErrorMessage,
      );
    }
  };

  if (assessment?.completed) {
    return (
      <Card w={360}>
        <Text style={{ wordBreak: "break-word", wordWrap: "normal" }}>
          {completeAssessmentMessage}
        </Text>
      </Card>
    );
  }

  return (
    <Stack>
      {questions.map((question, idx) => (
        <Stack spacing={4} key={question.stepId}>
          <Text size="xs" color="dimmed">
            Question {idx + 1}
          </Text>

          <Card withBorder pt="xs" radius="md">
            <Text
              my="sm"
              style={{ wordBreak: "break-word" }}
              dangerouslySetInnerHTML={{ __html: question.questionText }}
            />

            <QuestionInput
              question={question}
              answer={answers[question.stepId] ?? ""}
              setAnswer={(action) =>
                setAnswers((prev) => ({
                  ...prev,
                  [question.stepId]:
                    typeof action === "function"
                      ? action(prev[question.stepId])
                      : action,
                }))
              }
            />

            {!!errors[question.stepId]?.length && (
              <Text size="sm" mt="sm" color="red">
                {errors[question.stepId]}
              </Text>
            )}
          </Card>
        </Stack>
      ))}

      <Button mt="xs" fullWidth variant="filled" onClick={handleSubmit}>
        Submit
      </Button>
    </Stack>
  );
};

const getAssessmentQuestions = (
  questions: Record<string, AssessmentQuestion>,
  answers: Record<string, string>,
  question: AssessmentQuestion,
): AssessmentQuestion[] => {
  if (!question.next?.length) return [question];

  const answer = answers[question.stepId];

  const nextNodeId =
    question.next[0].response === "DEFAULT"
      ? question.next[0].nextStepId
      : question.next.find((q) => q.response === answer)?.nextStepId;

  const nextNode = nextNodeId ? questions[nextNodeId] : null;

  if (nextNode === question) return [question];
  if (nextNode)
    return [question, ...getAssessmentQuestions(questions, answers, nextNode)];

  return [question];
};

type QuestionInputProps = {
  question: AssessmentQuestion;
  answer: string;
  setAnswer: Dispatch<SetStateAction<string>>;
};

const QuestionInput = ({ question, answer, setAnswer }: QuestionInputProps) => {
  switch (question.answerType) {
    case AnswerType.Boolean:
      return (
        <BooleanInput setCurrentAnswer={setAnswer} currentAnswer={answer} />
      );
    case AnswerType.Number:
      return (
        <NumberInput setCurrentAnswer={setAnswer} currentAnswer={answer} />
      );
    case AnswerType.Text:
      return <TextInput setCurrentAnswer={setAnswer} currentAnswer={answer} />;
    case AnswerType.MultiChoice:
      return (
        <MultiChoiceInput
          setCurrentAnswer={setAnswer}
          answerOptions={question?.answerOptions ?? []}
          currentAnswer={answer}
        />
      );
    case AnswerType.Multi:
      return (
        <MultiInput
          setCurrentAnswer={setAnswer}
          answerOptions={question?.answerOptions ?? []}
          currentAnswer={answer}
        />
      );
    case AnswerType.Date:
      return (
        <DateInput
          name={question.stepId}
          setCurrentAnswer={setAnswer}
          currentAnswer={answer}
        />
      );
    default:
      return null;
  }
};
