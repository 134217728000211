import { X } from "react-feather";
import styled from "styled-components";
import { ActionInfo, getActionTitle } from "../util";
import { useAuthContext } from "src/hooks";
import { useFlowBuilderContext } from "../template-editor/context";
import { FlowActionBadge } from "./ActionBadge";
import { Permission } from "src/graphql";
import { Text, Tooltip } from "@mantine/core";

// magic number for height of a single-line-answer / 2
const ACTION_GUTTER_HEIGHT = 5;
const ACTION_PADDING = 0;
const ACTION_FONT_SIZE = 12;
const ACTION_LINE_HEIGHT = ACTION_FONT_SIZE * 1.5;
const CONNECTOR_BASE_HEIGHT_CSS = `calc(${ACTION_GUTTER_HEIGHT}px + ${ACTION_PADDING}px + .5 * ${ACTION_LINE_HEIGHT}px)`;
const ARM_TOP = `calc(${CONNECTOR_BASE_HEIGHT_CSS} - ${ACTION_GUTTER_HEIGHT}px - ${ACTION_PADDING}px)`;

const StyledContainer = styled.div`
  margin-top: ${ACTION_GUTTER_HEIGHT}px;
  padding: ${ACTION_PADDING}px;
  font-size: ${ACTION_FONT_SIZE}px;
  line-height: ${ACTION_LINE_HEIGHT}px;
  position: relative;
  display: flex;
`;

const ListConnector = styled.div`
  width: 20px;
  position: relative;

  // backbone of list connector
  &::before {
    display: block;
    content: "";
    position: absolute;
    left: 20%;
    top: -${ACTION_GUTTER_HEIGHT + ACTION_PADDING}px;
    width: 1px;
    height: ${(props) =>
      props.theme.isLastAction
        ? CONNECTOR_BASE_HEIGHT_CSS
        : `calc(100% + ${ACTION_GUTTER_HEIGHT}px + 2 * ${ACTION_PADDING}px)`};
    background-color: black;
  }

  // arm of list connect
  &::after {
    display: block;
    content: "";
    position: absolute;
    left: 20%;
    top: ${ARM_TOP};
    width: 50%;
    height: 1px;
    background-color: black;
  }
`;

export const DeleteActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--DANGER);
  color: white;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 2px;
  border-radius: 3px;
  cursor: pointer;
  opacity: 0;

  &:hover {
    background-color: var(--DANGER-HIGHLIGHT);
  }

  &:active {
    background-color: var(--DANGER);
  }

  ${StyledContainer}:hover & {
    opacity: 1;
  }
`;

type FlowActionItemProps = {
  onDeleteAction: (actionInfo: ActionInfo) => void;
  actionInfo: ActionInfo;
  isLastAction: boolean;
};

export const FlowActionItem = ({
  onDeleteAction,
  actionInfo,
  isLastAction,
}: FlowActionItemProps) => {
  const { hasAnyPermission } = useAuthContext();
  const {
    builderData: { actionOptionsByTypeById },
    readOnly,
  } = useFlowBuilderContext();

  const title = getActionTitle(actionOptionsByTypeById, actionInfo);

  return (
    <StyledContainer>
      <ListConnector theme={{ isLastAction }} />

      <div style={{ width: "100%", borderRadius: "999px" }}>
        {hasAnyPermission(Permission.BuilderToolsWriteAccess) && !readOnly && (
          <DeleteActionButton onClick={() => onDeleteAction(actionInfo)}>
            <X size={14} />
          </DeleteActionButton>
        )}

        <Tooltip
          label={title}
          maw={300}
          multiline
          withArrow
          position="bottom"
          withinPortal
        >
          <div>
            <FlowActionBadge actionType={actionInfo.actionType} />
            <Text p={3} lineClamp={1}>
              {title}
            </Text>
          </div>
        </Tooltip>
      </div>
    </StyledContainer>
  );
};
